import { Injectable } from '@angular/core';

import type { Nullable } from '@epi/types';

import { Store } from '../../../core/store/store';
import type { PermissionsMap } from '../models/user-role';

interface PermissionsState {
  permissions: Nullable<PermissionsMap>;
}

function createInitialState(): PermissionsState {
  return {
    permissions: null,
  };
}

@Injectable({ providedIn: 'root' })
export class PermissionsStore extends Store<PermissionsState> {
  constructor() {
    super({ name: 'Permissions', initialState: createInitialState() });
  }

  setPermissions(permissions: PermissionsMap): void {
    this.update((state) => ({ ...state, permissions: { ...state.permissions, ...permissions } }));
  }

  hasPermission<T extends keyof PermissionsMap>(groupKey: T, permission: keyof PermissionsMap[T]): boolean {
    if (!this.getValue().permissions) {
      return false;
    }
    return Boolean(this.getValue().permissions![groupKey][permission]);
  }
}
